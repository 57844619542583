
import { defineComponent, ref, computed, watch, onMounted, onUnmounted, Ref } from 'vue'

interface ITodo {
  completed: boolean,
  id: number,
  title: string
}
interface ITodoStorage {
  fetch: () => ITodo[],
  save: (todos: ITodo[]) => boolean | undefined,
  uid: number
}
interface IFilters {
  [x: string]: any,
  all: (todos: ITodo[]) => ITodo[],
  active: (todos: ITodo[]) => ITodo[],
  completed: (todos: ITodo[]) => ITodo[]
}

const STORAGE_KEY: string = "todos-vuejs-3.0"
const todoStorage: ITodoStorage = {
  fetch () {
    const todos: ITodo[] = JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]')
    todos.forEach((todo, index) => {
      todo.id = index
    })
    todoStorage.uid = todos.length
    return todos as ITodo[]
  },
  save (todos) {
    if (!Array.isArray(todos)) return false
    localStorage.setItem(STORAGE_KEY, JSON.stringify(todos))
  },
  uid: 0
}

const filters: IFilters = {
  all (todos) {
    return todos
  },
  active (todos) {
    return todos.filter(todo => {
      return !todo.completed
    });
  },
  completed (todos) {
    return todos.filter(todo => {
      return todo.completed
    })
  }
}

const Component = defineComponent({
  name: 'App',
  setup () {
    const todos = ref(todoStorage.fetch())
    const visibility = ref('all')
    const newTodo = ref('')
    const editedTodo: Ref<null | ITodo> = ref(null)
    const beforeEditCache = ref('')
    const filteredTodos = computed(() => filters[visibility.value](todos.value))
    const remaining = computed(() => filters.active(todos.value).length)
    const allDone = computed({
      get: () => remaining.value === 0,
      set: value => {
        todos.value.forEach(todo => {
          todo.completed = value
        })
      }
    })
    watch(todos, (todos) => {
      todoStorage.save(todos)
    }, { deep: true })
    onMounted(() => {
      window.addEventListener('hashchange', onHashChange)
    })
    onUnmounted(() => {
      window.removeEventListener('hashchange', onHashChange)
    })
    const addTodo = () => {
      const value = newTodo.value && newTodo.value.trim()
      if (!value) {
        return
      }
      todos.value.push({
        id: todoStorage.uid++,
        title: value,
        completed: false
      })
      newTodo.value= ''
    }
    const removeTodo = (todo: ITodo) => {
      todos.value.splice(todos.value.indexOf(todo), 1)
    }
    const editTodo = (todo: ITodo) => {
      beforeEditCache.value = todo.title
      editedTodo.value = todo
    }
    const doneEdit = (todo: ITodo) => {
      if (!editedTodo.value) {
        return
      }
      editedTodo.value = null
      todo.title = todo.title.trim()
      if (!todo.title) {
        removeTodo(todo)
      }
    }
    const cancelEdit = (todo: ITodo) => {
      editedTodo.value = null
      todo.title = beforeEditCache.value
    }
    const removeCompleted = () => {
      todos.value = filters.active(todos.value)
    }
    const onHashChange = () => {
        const visible: string = window.location.hash.replace(/#\/?/, '')
        if (filters[visible]) {
          visibility.value = visible
        } else {
          window.location.hash = ''
          visibility.value = ''
        }
      }
    return {
      todos,
      newTodo,
      editedTodo,
      visibility,
      remaining,
      allDone,
      filteredTodos,
      addTodo,
      removeTodo,
      editTodo,
      doneEdit,
      cancelEdit,
      removeCompleted
    }
  },
  directives: {
    'todo-focus' (el, binding) {
      if (binding.value) {
        el.focus()
      }
    }
  }
})

export default Component

